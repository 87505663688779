export const FORM_CONFIGURATION = {
  COMMON: {
    shadowModeControl: 'Shadow Mode Control',
    credentialName: 'Credential Name',
    environment: 'Environment',
    fakeCarrier: 'Fake Carrier',
    opcsExpected: 'Carrier Expects To Receive OPC Messages',
    minOpcPercentage: 'Minimum Expected percentage of OPC Messages',
    maxOpcPercentage: 'Maximum Expected percentage of OPC Messages',
    maxBtfHourlyRate: 'Maximum Allowable Number of BTF Messages In An Hour',
    optimizeRules: 'Optimize DQE Rules',
    hostedCarrier: 'Carrier Code',
    homeCountry: 'Countries considered "Domestic"',
    cohostedCarriers: 'Co-hosted Carriers',
    airlineBaseCity: 'Airline Base City',
    logLocation: 'Logs Location',
    settlingTime: 'Settling Time (in minutes)',
    connectionTimeMinutesDomestic: 'Domestic Flight Origin and Destination connection time in minutes',
    connectionTimeMinutesInternational: 'International Flight Origin and Destination connection time in minutes',
    createSdsNamePlusBookingSourceBlockingKey: 'Use blocking key consisting of the first two characters of the first and last name and the PNR booking source',
    createSdsNamePlusDobBlockingKey: 'Use blocking key consisting of the first two characters of the first and last name and the date of birth'
  },

  ATL: {
    firmableSegmentsWaitlisted: 'Process Waitlisted Segments',
    fixedTimeOfDay: 'Time of Day for Expiration',
    timeLimitTimezone: 'Time Zone Option',
    timeLimitTimezoneOptions: [
      {value: 'Point of sale', text: 'Point of sale'},
      {value: 'Origin first seg', text: 'PNR origin'},
      {value: 'Origin first airline seg', text: 'Origin of host/co-host airlines itinerary'},
      {value: 'Airline base city', text: 'Airline base city'},
      {value: 'UTC', text: 'UTC'}
    ],
    mostleastRestrictive: 'When rules have same priority apply',
    mostleastRestrictiveOptions: [
      {text: 'the most restrictive time limit', value: 'Most restrictive'},
      {text: 'the least restrictive time limit', value: 'Least restrictive'}
    ],
    minTL: 'Minimum Time Limit (in hours)',
    maxTL: 'Maximum Time Limit (in hours)',
    logicOfDaysPTD: 'Compute Days Prior to Departure',
    logicOfDaysPTDOptions: [
      {value: 'Per segment', text: 'Per segment'},
      {value: 'First active segment in PNR regardless of the airline code', text: 'First active segment in PNR regardless of airline code'},
      {value: 'First active segment in PNR for the considered airline', text: 'First active segment in PNR for the host/co-host airlines'}
    ],
    protectReaccommodation: 'Protect Re-accommodation',
    protectReaccommodationCondition: 'Protect Re-accommodation when FROM Segment is',
    protectReaccomodationOptions: [
      {text: 'Non-compliant', value: 'Non-compliant'},
      {text: 'Ticketed/Exempted', value: 'Compliant'}
    ],
    protectUpgrade: 'Protect Upgrade',
    protectUpgradeCondition: 'Protect Upgrade when FROM Segment is',
    protectUpgradeOptions: [
      {text: 'Non-compliant', value: 'Non-compliant'},
      {text: 'Ticketed/Exempted', value: 'Compliant'}
    ],
    upgradeDetection: 'Upgrade Detection Options',
    upgradeDetectionOptions: [
      {text: 'SSR FQTU associated to upgraded segment AND an EMD is associated to this SSR FQTU', value: 'FQTU + EMD'},
      {text: 'SSR FQTU associated to upgraded segment AND enhanced FHE is associated to upgraded segment', value: 'FHE association to upgraded seg'},
      {text: 'SSR FQTU associated to new upgraded segment', value: 'FQTU with old bkg class'},
      {text: 'SK PUPG containing the original booking class (prior upgrade) and associated to the new upgraded segment', value: 'SK PUPG'},
      {text: 'SSR STAR with the targeted upgrade booking class information', value: 'SSR STAR'}
    ],
    useFopTl: 'Protect Time Limit for specific Form Of Payments',
    enforceLTD: 'Enforce LTD over Manual Override',
    ttlAdjustmentOrderDefinition: 'Define Time Limit Adjustment Order',
    ttlAdjustmentOrderDefinitionOptions: [
      {text: 'Postpone to Next Business Day', value: 'NBZ'},
      {text: 'Set Fixed Time of Day', value: 'FXT'},
      {text: 'Apply Minimum TL', value: 'MIN'},
      {text: 'Apply Maximum TL', value: 'MAX'},
      {text: 'Cap TTL with LTD', value: 'LTD'}
    ],
    calendarManagement: 'Consider Week-ends and Holidays When warning Booking Source',
    pastWarnings: 'Warn Booking source Even When Warning Date Falls in the Past',
    marriedSegmentLogic: 'Action for Married Segments',
    marriedSegmentLogicOptions: [
      {text: 'Cancel only non-compliant segments (even if married)', value: 'Cancel only non-compliant segment even if married'},
      {text: 'Ignore the non-compliant and married segment', value: 'Ignore the non-compliant and married segment'},
      {text: 'Cancel the married segments (even if ticketed or exempted)', value: 'Cancel the married segments even if one is ticketed or exempted'}
    ],
    marriedSegmentQueue: 'Married Segment Queue',
    passengerNotification: 'Send notification to Passengers',
    enableSplitPNR: 'Split Partially Compliant PNRs',
    ineligibleSplitQueue: 'Ineligible Split Queue Location',
    errorQueue: 'Error Queue',
    enableQueuePartial: 'Queue Partially Compliant PNRs when Split is not Allowed',
    partialComplianceQueue: 'Partially Compliant PNRs Queue Location',
    atoctoQueueSetting: 'Queue Placement for ATO/CTO Bookings',
    useCID: 'Use Corporate IDs for ATO/CTO Bookings',
    enablePostEotQueue: 'Enable Post-EOT Queue Placement',
    enableWarningQueue: 'Enable Warning Queue Placement',
    enableExpirationQueue: 'Enable Expiration Queue Placement',
    listOfCIDs: 'List of Host Corporate IDs to Deactivate',
    listOfOIDs: 'List of Host Office IDs to Deactivate',
    officeQueueSetting: 'Disable Queuing to Booking Office',
    disableQueueTo1C8: 'OPC queuing 1C8',
    queueToBoardPointOid: 'Queue to Board Point Office Setting',
    enableQueueToBoardPointOid: 'Enable Queue to Board Point Office',
    boardPointToOidList: 'Board Point to Office ID List',
    actionOption: 'Action Options',
    actionOptionOptions: [
      {value: 'queue', text: 'Queue'},
      {value: 'cancel', text: 'Cancel MIS and SVC segments'}
    ],
    queue: 'Queue Location',
    hostSpaceAddition: 'Space addition',
    hostSpaceDeletion: 'Space deletion',
    hostSpaceBookingDateChange: 'Space booking date change',
    hostSpaceClassChange: 'Space booking class change',
    hostSegDateChange: 'Space date change',
    hostSpaceFlightNumberChange: 'Flight number change',
    hostSpaceBoardChange: 'Space board point change',
    hostSpaceOffChange: 'Space off point change',
    nonfirmableToFirmable: 'Space status code changed from non-firmable to firmable',
    firmableToNonfirmable: 'Space status code changed from firmable to non-firmable',
    hostSpaceKLToHK: 'Space status code changed from KL to HK',
    waitlistToFirmable: 'Space confirmed from waitlist',
    OASpaceChange: 'OA space change',
    updateOnPNROwner: 'Modification of PNR owner point of sale',
    updateOnPNRQueuing: 'Modification of queuing office point of sale',
    InformativeSegAddition: 'Informative space addition',
    updateOnTicket: 'Addition, modification or deletion of a trusted ticket',
    updateOnFP: 'Addition, modification or deletion of FP elements',
    updateOnFT: 'Addition, modification or deletion of FT',
    updateOnSKEXMP: 'SK EXMP addition or deletion',
    updateOnSKTLPW: 'SK TLPW addition or deletion',
    updateOnSKs: 'Other SKs',
    updateOnSKsWithCodes: 'SKs with codes',
    updateOnOSI: 'Addition, modification or deletion of an OSI',
    updateOnSSR: 'Addition, modification or deletion of an SSR with the exception of SSR TKNx and FQTx',
    updateOnSSRFQTx: 'Addition, modification or deletion of an SSR FQTx',
    updateOnRemarks: 'Addition, modification or deletion of a remark: RM, RC, RX, RI, RQ',
    splitPNR: 'PNR split by agent',
    updateOnTST: 'Addition, modification or deletion of a TST',
    updateOnNames: 'Addition, modification or cancellation of names'
  },

  GTL: {
    firmableSegmentsWaitlisted : 'Process Waitlisted Segments',
    fixedTimeOfDay: 'Time of Day for Expiration',
    timeLimitTimezone: 'Time Zone Option',
    timeLimitTimezoneOptions : [
      { value: 'Point of sale' , text: 'Point of sale queuing office'},
      { value: 'Origin first seg', text: 'PNR origin' },
      { value: 'Origin first airline seg', text: 'Origin of host/co-host airlines itinerary'},
      { value: 'Airline base city', text: 'Airline base city' },
      { value: 'UTC', text: 'UTC' }
    ],
    mostleastRestrictive: 'When rules have same priority apply',
    mostleastRestrictiveOptions : [
      { text: 'the most restrictive time limit', value: 'Most restrictive' },
      { text: 'the least restrictive time limit', value: 'Least restrictive' }
    ],
    minTL: 'Minimum Time Limit (in hours)',
    maxTL: 'Maximum Time Limit (in hours)',
    logicOfDaysPTD : 'Compute Days Prior to Departure',
    logicOfDaysPTDOptions : [
      { value: 'Per segment', 'text': 'Per segment' },
      { value: 'First active segment in PNR regardless of the airline code', text: 'First active segment in PNR regardless of airline code' },
      { value: 'First active segment in PNR for the considered airline', text: 'First active segment in PNR for the host/co-host airlines' }
    ],
    protectReaccommodation : 'Protect Re-accommodation',
    protectReaccommodationCondition : 'Protect Re-accommodation when FROM Segment is',
    protectReaccomodationOptions : [
      { text: 'Non-compliant', value: 'Non-compliant' },
      { text: 'Ticketed/Exempted', value: 'Compliant'}
    ],
    protectUpgrade : 'Protect Upgrade',
    protectUpgradeCondition : 'Protect Upgrade when FROM Segment is',
    protectUpgradeOptions : [
      { 'text': 'Non-compliant', value: 'Non-compliant' },
      { 'text': 'Ticketed/Exempted', value: 'Compliant'}
    ],
    upgradeDetection : 'Upgrade Detection Options',
    upgradeDetectionOptions : [
      { text: 'SSR FQTU associated to upgraded segment AND an EMD is associated to this SSR FQTU', value: 'FQTU + EMD' },
      { text: 'SSR FQTU associated to upgraded segment AND enhanced FHE is associated to upgraded segment', value: 'FHE association to upgraded seg' },
      { text: 'SSR FQTU associated to new upgraded segment', value: 'FQTU with old bkg class' },
      { text: 'SK PUPG containing the original booking class (prior upgrade) and associated to the new upgraded segment', value: 'SK PUPG' },
      { text: 'SSR STAR with the targeted upgrade booking class information', value: 'SSR STAR' }
    ],
    useFopTl : 'Protect Time Limit for specific Form Of Payments',
    agreementCompliance : 'Agreement Compliance',
    depositCompliance : 'Deposit Compliance',
    finalPaymentCompliance : 'Final Payment Compliance',
    nameCompliance : 'Name Compliance',
    ticketingCompliance : 'Ticketing Compliance',
    enforceLTD : 'Enforce LTD over Manual Override',
    ttlAdjustmentOrderDefinition : 'Define Time Limit Adjustment Order',
    ttlAdjustmentOrderDefinitionOptions : [
      { text : 'Postpone to Next Business Day', value: 'NBZ'},
      { text : 'Set Fixed Time of Day', value: 'FXT'},
      { text : 'Apply Minimum TL', value: 'MIN'},
      { text : 'Apply Maximum TL', value: 'MAX'},
      { text : 'Cap TTL with LTD', value: 'LTD'}
    ],
    calendarManagement : 'Consider Week-ends and Holidays When warning Booking Source',
    pastWarnings: 'Warn Booking source Even When Warning Date Falls in the Past',
    marriedSegmentLogic: 'Action for Married Segments',
    marriedSegmentLogicOptions : [
      { text: 'Cancel only non-compliant segments (even if married)', value: 'Cancel only non-compliant segment even if married'},
      { text: 'Ignore the non-compliant and married segment', value: 'Ignore the non-compliant and married segment'},
      { text: 'Cancel the married segments (even if ticketed or exempted)', value: 'Cancel the married segments even if one is ticketed or exempted'}
    ],
    marriedSegmentQueue : 'Married Segment Queue',
    conflictResolutionDefinition : 'At Deposit and Final Payment Time Limit expiration, if action is CANCEL and booking has ticketed passengers',
    conflictResolutionOptions:  [
      { text : 'Cancel all (even ticketed passengers)', value : 'cancel'},
      { text : 'Split PNR (to protect ticketed passengers)', value : 'split'},
      { text : 'Queue', value : 'queue'},
      { text : 'Ignore', value : 'ignore'}
    ],
    conflictResolutionQueue : 'Conflict Resolution Queue',
    passengerNotification : 'Send notification to Passengers',
    enableSplitPNR : 'Split Partially Compliant PNRs',
    ineligibleSplitQueue : 'Ineligible Split/Reduce Queue Location',
    errorQueue : 'Error Queue',
    enableQueuePartial : 'Queue Partially Compliant PNRs when Split is not Allowed',
    partialComplianceQueue : 'Partially Compliant PNRs Queue Location',
    PNRExclusionOverrideCredential: 'List of Authorized Users (for SSR waivers)',
    QueuevalidOverrides : 'Queue Valid Waiver',
    Queuelocationvalidoverrides : 'Valid Waiver Queue Location',
    QueueinvalidOverrides : 'Queue Invalid Waiver',
    Queuelocationinvalidoverrides: 'Invalid Waiver Queue Location',
    altQueuesEnabled: 'Enable Alternative Queue Locations',
    altQueueOfficeRequired: 'Enabled for Host offices',
    altQueuesWarning: {
      depositQueue : 'Deposit Queue',
      finalPaymentQueue: 'Final Payment Queue',
      nameQueue : 'Name Queue',
      ticketQueue: 'Ticket Queue',
      agreementQueue: 'Agreement Queue'
    },
    altQueuesExpiration: {
      depositQueue : 'Deposit Queue',
      finalPaymentQueue: 'Final Payment Queue',
      nameQueue : 'Name Queue',
      ticketQueue: 'Ticket Queue',
      agreementQueue: 'Agreement Queue'
    },
    atoctoQueueSetting: 'Queue Placement for ATO/CTO Bookings',
    depositEMDSetting:'Deposit EMD Settings',
    depositEMDSettingEnabled: 'Enable Group Deposit Validation',
    airlineDefaultCurrency:'Airline Default Currency',
    rficCodeForDeposit:'RFIC Code For Deposit',
    rfiscCodeForDeposit: 'RFISC Code For Deposit',
    toleranceMarginPercentage1: 'Tolerance Margin Percentage1',
    queueFortoleranceMargin1: 'Queue For Tolerance Margin1',
    toleranceMarginPercentage2:'Tolerance Margin Percentage2',
    queueFortoleranceMargin2:'Queue For Tolerance Margin2',
    useCID : 'Use Corporate IDs for ATO/CTO Bookings',
    useDP : 'Enable Custom Queue for Deposit/Payment',
    enablePostEotQueue: 'Enable Post-EOT Queue Placement',
    enableWarningQueue : 'Enable Warning Queue Placement',
    enableExpirationQueue : 'Enable Expiration Queue Placement',
    listOfCIDs: 'List of Host Corporate IDs to Deactivate',
    listOfQFDS : 'Queue for Split/Add-on PNR',
    listOfOIDs: 'List of Host Office IDs to Deactivate',
    actionOption: 'Action Options',
    actionOptionOptions: [
      {value: 'queue', text: 'Queue'},
      {value: 'cancel', text: 'Cancel MIS and SVC segments'}
    ],
    queue: 'Queue Location',
    hostSpaceAddition: 'Space addition',
    hostSpaceDeletion: 'Space deletion',
    hostSpaceBookingDateChange: 'Space booking date change',
    hostSpaceClassChange: 'Space booking class change',
    hostSegDateChange: 'Space date change',
    hostSpaceFlightNumberChange: 'Flight number change',
    hostSpaceBoardChange: 'Space board point change',
    hostSpaceOffChange: 'Space off point change',
    nonfirmableToFirmable: 'Space status code changed from non-firmable to firmable',
    firmableToNonfirmable: 'Space status code changed from firmable to non-firmable',
    hostSpaceKLToHK: 'Space status code changed from KL to HK',
    waitlistToFirmable: 'Space confirmed from waitlist',
    OASpaceChange: 'OA space change',
    updateOnPNROwner: 'Modification of PNR owner point of sale',
    updateOnPNRQueuing: 'Modification of queuing office point of sale',
    InformativeSegAddition: 'Informative segment addition',
    updateOnTicket: 'Addition, modification or deletion of a trusted ticket',
    updateOnFP: 'Addition, modification or deletion of FP elements',
    updateOnFT: 'Addition, modification or deletion of FT',
    updateOnSKEXMP: 'SK EXMP addition or deletion',
    updateOnSKTLPW: 'SK TLPW addition or deletion',
    updateOnSKs: 'Other SKs',
    updateOnSKsWithCodes: 'SKs with codes',
    updateOnOSI: 'Addition, modification or deletion of an OSI',
    updateOnSSR: 'Addition, modification or deletion of an SSR with the exception of SSR TKNx and FQTx',
    updateOnSSRFQTx: 'Addition, modification or deletion of an SSR FQTx',
    updateOnRemarks: 'Addition, modification or deletion of a remark: RM, RC, RX, RI, RQ',
    splitPNR: 'PNR split by agent',
    updateOnTST: 'Addition, modification or deletion of a TST',
    updateOnNames: 'Addition, modification or cancellation of names',
    increaseNumberInParty: 'Increase number in party',
    reduceNumberInParty: 'Reduce number in party'
  },

  NSM: {
    workableTypes: 'Un-Boarded Passenger Status Indicators',
    workableTypesOptions: [
      'NOSHO - Shown on PNL/ADL, but not checked in',
      'OFFLK - Passenger with OK ticket, shown on PNL/ADL, checked-in, but not departed',
      'OFFLN - Passenger with OK ticket, not shown on PNL/ADL, checked-in, but not departed',
      'GOSHN - Passenger with OK ticket, not shown on PNL/ADL, not departed',
      'CHGFL - Not covered under any other category, transferred to another flight due to flight irregularity',
      'CHGSG - Change of segment, passenger traveling to another destination than booked to'
    ],
    processGroups: 'Process Groups',
    workCodeShare: 'Process Codeshare PNRs',
    bookingActivity: 'Booking Activity After No-show',
    bookingActivityOptions: [
      {
        value: 'Down-line Cancellation is unaffected by Booking Activity',
        text: 'Downline Segment Cancellation is unaffected by booking activity'
      },
      {
        value: 'Down-line Cancellation will not occur if booking activity is on the host airline\'s/co-hosted airline\'s space',
        text: 'Downline Segment Cancellation will not occur if booking activity is on the Host/co-Host airline\'s space'
      },
      {
        value: 'Down-line Cancellation will not occur if booking activity is on the host airline\'s/co-hosted airline\'s space and is limited to the same Board & Off or O&D cities as the No Show flight',
        text: 'Downline Segment Cancellation will not occur if booking activity is on the Host/co-Host airline\'s space and is limited to the same Board & Off or O&D cities as the No-show flight'
      },
      {
        value: 'Downline cancellation will not occur if booking activity is on the Host airline\'s/co-Host airline\'s space and is limited to the same Board or Off or the same Origin or Destination cities as the No-show flight',
        text: 'Downline cancellation will not occur if booking activity is on the Host airline\'s/co-Host airline\'s space and is limited to the same Board or Off or the same Origin or Destination cities as the No-show flight'
      },
      {
        value: 'Down-line Cancellation will not occur if booking activity is on any airline\'s space',
        text: 'Downline Segment Cancellation will not occur if booking activity is on any airline\'s space'
      },
      {
        value: 'Down-line Cancellation will not occur if booking activity is on any airline\'s space and is limited to the same Board & Off or O&D cities as the No Show flight',
        text: 'Downline Segment Cancellation will not occur if booking activity is on any airline\'s space and is limited to the same Board & Off or O&D cities as the No-show flight'
      },
      {
        value: 'Downline cancellation will not occur if booking activity is on any airline\'s space and is limited to the same Board or Off or the same Origin or Destination cities as the No-show flight',
        text: 'Downline cancellation will not occur if booking activity is on any airline\'s space and is limited to the same Board or Off or the same Origin or Destination cities as the No-show flight'
      },
      {
        value: 'Down-line Cancellation will not occur if the same Board & Off or O&D cities operating within 24 hours of the No Show flight are present in the PNR, regardless of when the space was added',
        text: 'Downline Segment Cancellation will not occur if the same Board & Off or O&D cities operating within 24 hours of the No-show flight are present in the PNR, regardless of when the space was added'
      },
      {
        value: 'Downline cancellation will not occur if the same Board or Off or the same Origin or Destination cities operating within 24 hours of the No-show flight are present in the PNR, regardless of when the space was added',
        text: 'Downline cancellation will not occur if the same Board or Off or the same Origin or Destination cities operating within 24 hours of the No-show flight are present in the PNR, regardless of when the space was added'
      }
    ],
    workAllNoshows: 'Work All No Shows When More Than One Are Received',
    cancelSettings: 'Cancel Settings',
    cancelSettingsOptions: [
      {
        value: 'Cancel all down-line Host/Co-hosted space',
        text: 'Cancel all downline Host/co-Host flights'
      },
      {
        value: 'Cancel only Host/Co-hosted flights in the same O&D as the No Show flight',
        text: 'Cancel only Host/co-Host flights in the same O&D as the No-show flight'
      },
      {
        value: 'Cancel all Host/Co-hosted space on the same day of travel as the No Show flight',
        text: 'Cancel all Host-co-Host flights on the same day of travel as the No-show flight'
      },
      {
        value: 'Cancel all Host/Co-hosted space on the same ticket as the No Show flight',
        text: 'Cancel all Host/co-Host flights on the same ticket as the No-show flight'
      },
      {
        value: 'Queue in lieu of cancel',
        text: 'Queue in lieu of cancel'
      },
    ],
    inLieuCancelQueue: 'Queue Location',
    splitDividePartial: 'Split Partially Boarded PNRs',
    splitDividePartialQueue: 'Partially Boarded Queue Location',
    ineligibleSplitDividePartialQueue: 'Ineligible Split Partially Boarded Queue Location',
    splitDivideGroups: 'Split Partially Boarded Group PNRs',
    splitDivideGroupsQueue: 'Group Boarded Queue Location',
    ineligibleSplitDivideGroupsQueue: 'Ineligible Split Partially Boarded Group Queue Location',
    retrieveDeparturePlan: 'Check for Actual Time of Departure of the No-Show Segment',
    boardPortsExcludedFromDeparturePlanCheck: 'No-Show Segment Board Points Excluded from the Actual Time of Departure Check',
    settlingTime: 'Processing Delay (in minutes)',
    errorQueue: 'Error Queue',
    errorHandlingRules: 'Error Handling Rules',
    allowOverrides: 'Allow Manual Overrides',
    overrideUsers: 'Authorized Users of Manual Overrides',
    validOverrideQueue: 'Valid Override Queue Location',
    invalidOverrideQueue: 'Invalid Override Queue Location',
    dynamicWaiverSettings: 'Dynamic Waiver Settings',
    enableDynamicWaiver: 'Enable Dynamic Waiver',
    enableWBSErrorMessage: 'Enable WBS Error Message',
    WBSErrorMessage: 'WBS Error SSR OTHS Message',
    dynamicWaiverErrorQueue: 'Dynamic Waiver Error Queue',
    hostBookingMessageName: 'Host/co-Host',
    hostBookingMessageNameOptions: [
      { 'text': 'SSR OTHS', 'value': 'SSR_OTHS'},
      { 'text': 'RM', 'value': 'RM'},
      { 'text': 'RM/L', 'value': 'restrictedRemark'}
    ],
    rmlMessageAuthorizedOffices: 'Authorized Offices for RM/L Message',
    nonHostBookingMessageName: 'Non-Host',
    nonHostBookingMessageNameOptions: [
      {value: 'SSR_OTHS', text: 'SSR OTHS'}
    ],
    overrideAndWaiversMessageName: 'Message Format',
    overrideAndWaiversMessageNameOptions: [
      {value: 'SSR_OTHS', text: 'SSR OTHS'},
      {value: 'OSI', text: 'OSI'},
      {value: 'RM', text: 'RM'}
    ],

    eticketConfigs: {
      eticketRevokeActive: 'Enable E-Ticket Coupon Update',
      eTicketActions: 'Select the eligible coupons eligible for processing due to a No-show passenger',
      eTicketActionsOptions: [
        {
          value: 'Change coupon status only on the No Show flights.',
          text: 'Change coupon status only on the No-show flights'
        },
        {
          value: 'Change coupon status on all down-line Host/co-Hosted flights.',
          text: 'Change coupon status on the No-show flights and all downline Host/co-Host flights'
        },
        {
          value: 'Change coupon status on only Host/co-Hosted flights in the same O&D as the No-Show flight.',
          text: 'Change coupon status on only Host/co-Host flights in the same O&D as the No-show flights'
        },
        {
          value: 'Change coupon status on all Host/co-hosted flights departing on the same calendar day of travel as the No-Show.',
          text: 'Change coupon status on all Host/co-Host flights departing on the same calendar day of travel as the No-show flight'
        },
        {
          value: 'Change coupon status on all Host/co-Hosted flights on the same ticket as the No-Show flight.',
          text: 'Change coupon status on all Host/co-Host flights on the same ticket as the No-show flight'
        },
      ],
      specificTicketNumbers: 'Airline Accounting Code - Provide the Host/co-Host airline accounting code(s) of tickets eligible for coupon updates',
      workableTypes: 'Select the coupon status codes eligible for update. processing due to a No-show passenger',
      workableTypesOptions: [
        'A - Airport Control',
        'C - Checked In',
        'I - Irregular Operation',
        'O - Open For Use',
      ],
      processGroups: 'Process Groups',
      groupAction: 'Alternate Action For Group PNRs',
      groupActionOptions: [
        {text: 'None', value: ''},
        {text: 'Queue', value: 'Queue'},
        {text: 'Revoke (Q)', value: 'Revoke'},
        {text: 'Suspend (S)', value: 'Suspend'},
        {text: 'Close (Z)', value: 'Close'},
        {text: 'Unavailable (U)', value: 'Unavailable'}
      ],
      groupsQueue: 'Queue For Group PNRs',
      codeshareOption: 'Alternate Action for Codeshare PNRs',
      codeshareOptions: [
        {text: 'All coupons are subject to standard business rules'},
        {text: 'Codeshare coupons are exempt from action'},
        {text: 'All coupons are exempt from action'}
      ],
      tieBreakerSettingLevel: 'Allow Different Coupons on the Same Ticket to Receive Different Status Updates',
      tieBreakerSetting: 'Conflict Resolution When Revoke and Suspend Are Defined on the Same Ticket or Coupon',
      tieBreakerSettingOptions: [
        {text: 'Queue', value: 'Queue'},
        {text: 'Revoke (Q)', value: 'Revoke'},
        {text: 'Suspend (S)', value: 'Suspend'},
        {text: 'Close (Z)', value: 'Close'},
        {text: 'Unavailable (U)', value: 'Unavailable'}
      ],
      tieBreakerQueue: 'Queue For PNRs Where Revoke And Suspend Are Defined On The Same Coupon',
      allowOverrides: 'Allow Manual Overrides',
      overrideUsers: 'Users Allowed To Enter Manual Override Phrases',
      validOverrideQueue: 'Valid Override Queue Location',
      invalidOverrideQueue: 'Invalid Override Queue Location',
      eTicketErrorQueue: 'ETicket Processing Error Queue'
    }
  },

  DPS: {
    enableProcessor : 'Enable Duplicate Segments',
    settlingTime : 'Settling Time (in minutes)',
    fixedTimeOfDay: 'Time of Day for Expiration',
    timeLimitTimeZone: 'Time Zone Option',
    timeLimitTimeZoneOptions : [
      { value: 'Point of sale' , text: 'Point of sale'},
      { value: 'Origin first seg', text: 'PNR origin' },
      { value: 'Origin first airline seg', text: 'Origin of host/co-host airlines itinerary'},
      { value: 'Airline base city', text: 'Airline base city' },
      { value: 'UTC', text: 'UTC' }
    ],
    mostleastRestrictive: 'Action Conflict Resolution',
    mostleastRestrictiveOptions : [
      { text: 'Separate between Immediate and Most Restrictive TL' },
      { text: 'Apply Most Restrictive TL' }
    ],
    flightToUseInCalculatingPTD: 'Flight to Use in Calculating Prior to Departure',
    flightToUseInCalculatingPTDOptions : [
      { text: 'First non-flown segment in the PNR', value: 'FIRST ACTIVE' },
      { text: 'First non-flown carrier segment', value: 'FIRST ACTIVE CARRIER FLIGHT' }
    ],
    maxTL: 'Maximum Time Limit (in hours)',
    tooCloseToFirm: 'Too Close to Firm (in hours)',
    actionMessageTypeForHost: 'Host/co-Host',
    actionMessageTypeForHostOptions : [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'RM/L', value: 'restrictedRemark'}
    ],
    rmlMessageAuthorizedOffices: 'Authorized Offices for RM/L Message',
    actionMessageTypeForNonHost: 'Non-Host',
    actionMessageTypeForNonHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'}
    ],
    maximumNumberOfDayBetweenDupe: 'Maximum Number of Hours between Duplication',
    maximumSpeed: 'Maximum Travel Speed (km/h)',
    discardContiguousItin: 'Discard Contiguous Itinerary',
    decisionPriorityOption : 'Decision Priority Option',
    decisionPriorityOptionOptions : [
      { text : 'Ticketed vs. un-ticketed', value: 'ticket' },
      { text : 'Married vs. un-married', value: 'married' },
      { text : 'HK segment vs. KL segment', value: 'hkOverKl' },
      { text : 'Booking Class', value: 'bookingClass' },
      { text : 'Confirmation Time', value: 'bookingTime' },
      { text : 'Departure Time', value: 'departureTime' }
    ],
    decisionPrioritySegmentBookingTime: 'Decision Priority within Segment Confirmation Time',
    decisionPrioritySegmentBookingTimeOptions : [
      { text: 'Action The Most Recently Confirmed', value: 'The Most Recent Booked'},
      { text: 'Action The Least Recently Confirmed', value: 'The Least Recent Booked' }
    ],
    decisionPriorityDepartureTime: 'Decision Priority within Segment Departure Time',
    decisionPriorityDepartureTimeOptions : [
      { text: 'Action The Soonest Departing Segment', value: 'The Soonest Departing Segment' },
      { text: 'Action The Latest Departing Segment', value: 'The Latest Departing Segment' }
    ],
    internationalClassHierarchy: 'International Booking Class Hierarchy',
    domesticClassHierarchy: 'Domestic Booking Class Hierarchy',
    enableProtectHosts: 'Enable Protect Host Added Segments',
    hostProtectionTime: 'Number of Hours for Host Dupes Protection',
    enableProcessGroups: 'Enable Processing Groups',
    groupPNROptions: 'Group PNR Processing Options',
    specialHandlingOptions : [
      { text: 'Follow standard rule', value: 'standard' },
      { text: 'Queue to a specific queue', value: 'queue' }
    ],
    overrideQueue : 'Queue Location for Group PNRs',
    enableProcessOAs: 'Enable Processing Other Airlines\' Segments',
    oaSegmentsOptions: 'Other Airlines\' Segments Processing Options',
    overrideQueueOA : 'Queue Location for Other Airlines\' Segments',
    tktSegmentsOptions: 'Ticketed Segments Processing Options',
    cancelMarriedSegments: 'Enable Actioning of Entire Married O&D',
    overrideQueueTkt : 'Queue Location for Ticketed Segments',
    ineligibleSplitQueue: 'Ineligible Split Queue Location',
    errorQueue: 'Error Queue',
    pnrExclusionOverrideCredential: 'List of Authorized Users (for SSRs waivers and SSR overrides)',
    queueValidOverrides: 'Queue Valid Waiver/Overrides',
    queueLocationValidOverrides: 'Valid Waiver/Override Queue Location',
    queueInvalidOverrides: 'Queue Invalid Waiver/Overrides',
    queueLocationInvalidOverrides: 'Invalid Waiver/Override Queue Location',
    ssrPnrWaiver: 'PNR Waiver Message Syntax',
    ssrSegmentWaiver: 'Segment Waiver Message Syntax',
    ssrOverride: 'Time Limit Override Message Syntax',
    waiverOverrideMessageType: 'Message Type for Override and Waiver',
    waiverOverrideMessageTypeOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'OSI', value: 'osi'}
    ]
  },

  FSN: {
    enableProcessor: 'Enable Fictitious & Suspicious Names',
    settlingTime: 'Settling Time (in minutes)',
    passengerTitles: 'Passenger Titles/Suffixes',
    fixedTimeOfDay: 'Time of Day for Expiration',
    timeLimitTimezone: 'Time Zone Option',
    timeLimitTimezoneOptions: [
      { value: 'Point of sale' , text: 'Point of sale'},
      { value: 'Origin first seg', text: 'PNR origin' },
      { value: 'Origin first airline seg', text: 'Origin of host/co-host airlines itinerary'},
      { value: 'Airline base city', text: 'Airline base city' },
      { value: 'UTC', text: 'UTC' }
    ],
    mostleastRestrictive: 'When rules have same priority apply',
    mostleastRestrictiveOptions: [
      { text: 'the most restrictive time limit', value: 'Most restrictive' },
      { text: 'the least restrictive time limit', value: 'Least restrictive' }
    ],
    minTL: 'Minimum Time Limit (in hours)',
    maxTL: 'Maximum Time Limit (in hours)',
    enableProcessGroups: 'Enable Processing Groups',
    groupPnrAction: 'Action For Group PNRs',
    groupPnrActionOptions: [
      { text: 'Standard Action' },
      { text: 'Override Queuing' }
    ],
    overrideQueue: 'Queue Location for Group PNRs',
    emailWhiteListPassengers: 'Enable Email notification for Allowlist Names at initial discovery',
    whitelistEmailSkType: 'SK type for Allowlist email notification messages',
    segmentAssociateWhitelistEmail: 'Add segment associations for Allowlist notification messages when itinerary change',
    emailBlackListPassengers: 'Enable Email notification for Blocklist Names at initial discovery',
    blacklistEmailSkType: 'SK type for Blocklist email notification messages',
    segmentAssociateBlacklistEmail: 'Add segment associations for Blocklist notification messages when itinerary change',
    actionMessageTypeForHost: 'Host/co-Host',
    actionMessageTypeForHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'RM/L', value: 'restrictedRemark'}
    ],
    remarkOffices: 'List of Office IDs',
    actionMessageTypeForNonHost: 'Non-Host',
    actionMessageTypeForNonHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'}
    ],

    ineligibleSplitQueue: 'Ineligible Split Queue Location',
    errorQueue: 'Error Queue',
    pnrExclusionOverrideCredential: 'List of Authorized Users (for SSRs waivers and SSR overrides)',
    queueValidOverrides: 'Queue Valid Waiver/Overrides',
    queueLocationValidOverrides: 'Valid Waiver/Override Queue Location',
    queueInvalidOverrides: 'Queue Invalid Waiver/Overrides',
    queueLocationInvalidOverrides: 'Invalid Waiver/Override Queue Location',
    ssrWaiver: 'Waiver Message Syntax',
    ssrOverride: 'Time Limit Override Message Syntax',
    waiverOverrideMessageType: 'Message Type for Override and Waiver',
    waiverOverrideMessageTypeOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'OSI', value: 'osi'}
    ]
  },

  DPP: {
    'enableProcessor' : 'Enable Duplicate PNR',
    'settlingTime' : 'Settling Time (in minutes)',
    'fixedTimeOfDay': 'Time of Day for Expiration',
    'timeLimitTimezone': 'Time Zone Option',
    'timeLimitTimezoneOptions' : [
      { 'value': 'pnrQueuingOffice' , 'text': 'Point of sale'},
      { 'value': 'firstFlightCity', 'text': 'PNR origin' },
      { 'value': 'firstHostCohostCity', 'text': 'Origin of host/co-host airlines itinerary'},
      { 'value': 'airlineBaseCity', 'text': 'Airline base city' },
      { 'value': 'utc', 'text': 'UTC' }
    ],
    'flightToUseInCalculatingPTD': 'Flight to Use in Calculating Prior to Departure',
    'flightToUseInCalculatingPTDOptions' : [
      { 'text': 'First Non-Flown Segment Between PNR Pair', 'value': 'FIRST ACTIVE FLIGHT' },
      { 'text': 'First Non-Flown Host/Co-Host Segment Between PNR Pair', 'value': 'FIRST ACTIVE CARRIER FLIGHT' }
    ],
    'minTL': 'Minimum Time Limit (in hours)',
    'maxTL': 'Maximum Time Limit (in hours)',
    'tooCloseToFirm': 'Too Close to Firm (in hours)',
    'maximumSpeed': 'Maximum Travel Speed (km/h)',
    'allHostBookingAsSameBookingSource': 'All Host Bookings Considered Same Booking Source',
    'processOperatingSegments' : 'Allow firming Single PNR Operating Segments',
    'enableProtectHosts': 'Enable Protect Host Added Segments',
    'hostProtectionTime': 'Number of Hours for Host Dupes Protection',
    'tlIfDifferentBookingSource': 'Assign TL If Different Booking Source Involved',
    'differentBookingSourceAction' : 'Action',
    'differentBookingSourceActionOptions' : [
      { 'text': 'Immediate Action', 'value': 'immediate' },
      { 'text': 'Queue Placement', 'value': 'queue' }
    ],
    'frequentFlyerElements' : 'FrequentFlier Options',
    'frequentFlyerElementsOptions' : [
      { 'text': 'FQTR', 'value': 'fqtr' },
      { 'text': 'FQTV', 'value': 'fqtv' },
      { 'text': 'FQTR and FQTV', 'value': 'fqtr and fqtv' }
    ],
    'differentBookingSourceQueue' : 'Queue for Different Booking Source Action',
    'enableProcessGroups': 'Enable Processing Groups',
    'groupPNROptions': 'Group PNR Processing Options',
    'specialHandlingOptions' : [
      { 'text': 'Follow standard rule', 'value': 'standard' },
      { 'text': 'Queue to a specific queue', 'value': 'queue' }
    ],
    'specialHandlingForPax' : [
      { 'text': 'Unticketed Pax', 'value': 'unticketed'},
      { 'text': 'Ticketed and Unticketed Pax', 'value': 'ticketedAndUnticketed'}
    ],
    'overrideQueue' : 'Queue Location for Group PNRs',
    'tktSegmentsOptions': 'Ticketed PNRs Processing Options',
    'duplicatePassengerOptions': 'Pax to be Actioned',
    'overrideQueueTkt' : 'Queue Location for Ticketed Segments',
    'overrideQueuePax' : 'Queue Location for Ticketed Pax',
    'hostCohostGenericMessage': 'Generic Product Message for Host/Co-Host',
    'listOfOIDsForHostGeneric' : 'List of Authorized Office Ids for Restricted Remark',
    'hostCohostPnrMessage': 'Record Locator Message for Host/Co-Host',
    'listOfOIDsForHostPnr' : 'List of Authorized Office Ids for Restricted Remark',
    'nonHostGenericMessage': 'Generic Product Message for Non-Host',
    'nonHostPnrMessage': 'Record Locator Message for Non-Host',
    'listOfOIDsForNonHostPnr' : 'List of Authorized Office Ids for Restricted Remark',
    'messagingOption' : [
      { 'text': 'Restricted Remark', 'value': 'restrictedRemark' },
      { 'text': 'SSR OTHS', 'value': 'ssroths' },
      { 'text': 'Unrestricted Remark', 'value': 'unrestrictedRemark' }
    ],
    'enableSplitPNR' : 'Allow Split for Partial Duplication',
    'ineligibleSplitQueue' : 'Ineligible Split Queue Location',
    'partialDuplicatePNRQueue' : 'Partial Duplicate PNRs Queue Location',
    'errorQueue': 'Error Queue',
    'pnrExclusionOverrideCredential': 'List of Authorized Users (for SSRs waivers and SSR overrides)',
    'queueValidOverrides': 'Queue Valid Waiver/Overrides',
    'queueLocationValidOverrides': 'Valid Waiver/Override Queue Location',
    'queueInvalidOverrides': 'Queue Invalid Waiver/Overrides',
    'queueLocationInvalidOverrides': 'Invalid Waiver/Override Queue Location',
    'ssrPnrWaiver': 'PNR Waiver Message Syntax',
    'ssrOverride': 'Time Limit Override Message Syntax',
    'decisionPriorityOrderDefinition' : 'Define Decision Priority Order',
    'decisionPriorityDefinitionOptions' : [
      { 'text' : 'Number of Ticketed Passenger(s)', 'value': 'noOfTicketedPassenger' },
      { 'text' : 'Domestic vs. International', 'value': 'domesticOrInternational' },
      { 'text' : 'Booking Class', 'value': 'bookingClass' },
      { 'text' : 'Individual vs. Group', 'value': 'individualOrGroup' },
      { 'text' : 'PNR Departure Time', 'value': 'departureTime' },
      { 'text' : 'Number of Passenger(s)', 'value': 'noOfPassengers' },
      { 'text' : 'Number of Confirmed Segments', 'value': 'confirmedSegments' },
      { 'text' : 'PNR Creation Time', 'value': 'creationTime' },
      { 'text' : 'Number of Ticketed Duplicate Passengers', 'value': 'noOfTicketedDuplicatePassenger'}
    ],
    'activateRandom': 'Activate Random',
    'randomLogicOption': 'Apply Random at ',
    'randomLogicOptionList': [
      { 'value': 'bottom' , 'text': 'Bottom of the Decision Priority' },
      { 'value': 'top', 'text': 'Top of the Decision Priority' }
    ],
    'noOfPassengers': 'Number of passenger(s)',
    'noOfPassengersOptions' : [
      { 'value': 'more' , 'text': 'Most number of passenger(s)' },
      { 'value': 'less', 'text': 'Least number of passenger(s)' }
    ],
    'confirmedSegments': 'Number of confirmed segment(s)',
    'confirmedSegmentsOptions' : [
      { 'value': 'more' , 'text': 'Most number of confirmed segment(s)' },
      { 'value': 'less', 'text': 'Least number of confirmed segment(s)' }
    ],
    'creationTime': 'PNR Creation Time',
    'creationTimeOptions' : [
      { 'value': 'mostRecent' , 'text': 'Most recently created' },
      { 'value': 'leastRecent', 'text': 'Least recently created' }
    ],
    'departureTime': 'PNR Departure Time',
    'departureTimeOptions' : [
      { 'value': 'earlier' , 'text': 'Earliest departing PNR' },
      { 'value': 'later', 'text': 'Latest departing PNR' }
    ],
    'internationalClassHierarchy': 'International Booking Class Hierarchy',
    'domesticClassHierarchy': 'Domestic Booking Class Hierarchy',
    'domesticOrInternational': 'Domestic vs. International',
    'domesticOrInternationalOptions' : [
      { 'value': 'international' , 'text': 'International PNR' },
      { 'value': 'domestic', 'text': 'Domestic PNR' }
    ],
    'individualOrGroup': 'Individual vs. Group',
    'individualOrGroupOptions' : [
      { 'value': 'individual' , 'text': 'Individual PNR' },
      { 'value': 'group', 'text': 'Group PNR' }
    ],
    'noOfTicketedDuplicatePassenger': 'Number of Ticketed Duplicate Passengers',
    'noOfTicketedDuplicatePassengerOptions' : [
      { 'value': 'more', 'text': 'Most number of ticketed duplicate passenger(s)'},
      { 'value': 'less', 'text': 'Least number of ticketed duplicate passenger(s)'}
    ],
  },

  TTT: {
    firmableSegmentsWaitlisted: 'Process Waitlisted Segments',
    fixedTimeOfDay: 'Time of Day for Expiration',
    timeLimitTimezone: 'Time Zone Option',
    timeLimitTimezoneOptions: [
      {value: 'Point of sale', text: 'Point of sale'},
      {value: 'Origin first seg', text: 'PNR origin'},
      {value: 'Origin first airline seg', text: 'Origin of host/co-host airlines itinerary'},
      {value: 'Airline base city', text: 'Airline base city'},
      {value: 'UTC', text: 'UTC'}
    ],
    mostleastRestrictive: 'When rules have same priority apply',
    mostleastRestrictiveOptions: [
      {text: 'the most restrictive time limit', value: 'Most restrictive'},
      {text: 'the least restrictive time limit', value: 'Least restrictive'}
    ],
    minTL: 'Minimum Time Limit (in hours)',
    maxTL: 'Maximum Time Limit (in hours)',
    logicOfDaysPTD: 'Compute Days Prior to Departure',
    logicOfDaysPTDOptions: [
      {value: 'Per segment', text: 'Per segment'},
      {value: 'First active segment in PNR regardless of the airline code', text: 'First active segment in PNR regardless of airline code'},
      {value: 'First active segment in PNR for the considered airline', text: 'First active segment in PNR for the host/co-host airlines'}
    ],
    protectReaccommodation: 'Protect Re-accommodation',
    protectReaccommodationCondition: 'Protect Re-accommodation when FROM Segment is',
    protectReaccomodationOptions: [
      {text: 'Non-compliant', value: 'Non-compliant'},
      {text: 'Ticketed/Exempted', value: 'Compliant'}
    ],
    useFopTl: 'Protect Time Limit for specific Form Of Payments',
    enforceLTD: 'Enforce LTD over Manual Override',
    calendarManagement: 'Consider Week-ends and Holidays When warning Booking Source',
    pastWarnings: 'Warn Booking source Even When Warning Date Falls in the Past',
    marriedSegmentLogic: 'Action for Married Segments',
    marriedSegmentLogicOptions: [
      {text: 'Cancel only non-compliant segments (even if married)', value: 'Cancel only non-compliant segment even if married'},
      {text: 'Ignore the non-compliant and married segment', value: 'Ignore the non-compliant and married segment'},
      {text: 'Cancel the married segments (even if ticketed or exempted)', value: 'Cancel the married segments even if one is ticketed or exempted'}
    ],
    marriedSegmentQueue: 'Married Segment Queue',
    passengerNotification: 'Send notification to Passengers',
    enableSplitPNR: 'Split Partially Compliant PNRs',
    ineligibleSplitQueue: 'Ineligible Split Queue Location',
    errorQueue: 'Error Queue',
    enableQueuePartial: 'Queue Partially Compliant PNRs when Split is not Allowed',
    partialComplianceQueue: 'Partially Compliant PNRs Queue Location',
    queue: 'Queue Location'
  },

  HDG: {
    enableProcessor : 'Enable Hidden Groups',
    enableSplitPNR: 'Split Partially Compliant PNRs',
    ineligibleSplitQueue: 'Ineligible Split Queue Location',
    errorQueue: 'Error Queue',
    partialDetectionQueue: 'Partial Detection Queue',
    hostCohostGenericMessage: 'Generic Product Message for Host/Co-Host',
    nonHostGenericMessage: 'Generic Product Message for Non-Host',
    remarkOffices : 'List of Authorized Office Ids for Restricted Remark',
    partialComplianceQueue: 'Partially Detection PNRs Queue Location',
    tooCloseToFirm: 'Too Close to Firm (in hours)',
    individualSegmentsOrOd: 'Hidden Groups Detection Level',
    individualSegmentsOrOdOptions: [
      {text: 'Individual Segments', value: 'INDIVIDUAL_SEGMENTS', disabled: false},
      {text: 'O & D', value: 'OD', disabled: true}
    ],
    messagePnr: 'Message PNR',
    oaSegments: 'Include OA Segments',
    messagingOption : [
      { 'text': 'Restricted Remark', 'value': 'restrictedRemark' },
      { 'text': 'SSR OTHS', 'value': 'ssroths' },
      { 'text': 'Unrestricted Remark', 'value': 'unrestrictedRemark' }
    ],
    searchWindowHours: 'Search Window Detection (in hours):',
    statusCode: 'Include Segment Status'
  },

  WLT: {
    workableWaitlistSegmentStatus: 'Workable Unproductive Segment Status Configuration',
    useDefaultWaitlistSegmentStatus: 'Use Default Waitlist Segment Status',
    useDefaultWaitlistSegmentStatusDescription: '[AW,CL,CU,CW,DW,HL,IW,LF,LG,LL,PA,PB,PC,PD,PE,PG,TL,US,UU]',
    additionalWaitlistSegmentStatuses: 'Additional Unproductive Segment Statuses',
    customWaitlistSegmentStatuses: 'Additional Unproductive Segment Statuses',
    timeLimitBase: 'Time Limit Options',
    timeLimitBaseOption: [
      {value: 'Days prior to departure', text: 'Days prior to departure'},
      {value: 'Hours prior to departure', text: 'Hours prior to departure'},
      {value: 'Days from discovery of Waitlist segments', text: 'Days from discovery of Unproductive segments'},
      {value: 'Days from booking date of Waitlist segments', text: 'Days from booking date of Unproductive segments'}
    ],
    fixedTimeOfDay: 'Time of Day for Expiration',
    minimumTimelimit: 'Minimum Time Limit (in hours)',
    maximumTimelimit: 'Maximum Time Limit (in hours)',
    tooCloseToDepartureTimeFrame: 'Too Close to Departure Time Frame (in hours)',
    applyTimelimitMessage: 'Messaging the Booking Source',
    timeLimitTimezone: 'Time Zone Options',
    timelimitTimeZoneOnMessage: [
      {value: 'Point of sale time zone', text: 'Point of sale'},
      {value: 'Flight point of origin time zone', text: 'PNR origin'},
      {value: 'Host/carrier base city time zone', text: 'Airline base city'},
      {value: 'Zulu', text: 'UTC'}
    ],
    waitlistSegmentCancelLogic: 'Unproductive Segment Cancel Logic',
    waitlistSegmentCancelLogicOption: [
      {value: 'Waitlist segments will be removed individually', text: 'Unproductive segments will be removed individually'},
      {value: 'Waitlist O&D will be removed at the same time', text: 'Unproductive O&D will be removed at the same time'},
      {value: 'Waitlist space for same day travel will be removed at the same time', text: 'Unproductive space for same day travel will be removed at the same time'},
      {value: 'All Waitlist space will be removed at the same time', text: 'All unproductive space will be removed at the same time'}
    ],
    allWaitlistSegmentCancelLogic: 'Options to Cancel All Unproductive Segments at the Same Time',
    allWaitlistSegmentCancelLogicOptions: [
      {value: 'Only remove all Waitlist space if NO CONFIRMED HOST SPACE exists', text: 'Only remove all unproductive space if no confirmed host space exists'},
      {value: 'Remove all Waitlist space, EVEN IF CONFIRMED HOST SPACE exists', text: 'Remove all unproductive space, even if confirmed host space exists'}
    ],
    overrideUsers: 'List of Authorized Users (for SSR waivers/overrides)',
    useValidOverrideQueue : 'Queue Valid Waiver/Overrides',
    validOverrideQueue : 'Valid Waiver Queue Location',
    useInvalidOverrideQueue : 'Queue Invalid Waiver/Overrides',
    invalidOverrideQueue: 'Invalid Waiver Queue Location',
    errorQueue: 'Error Queue',
    workGroupPNR: 'Enable Processing Groups',
    groupPNROptions: 'Group PNR Processing Options',
    specialHandlingOptions : [
      { 'text': 'Follow standard rule', 'value': 'follow rule action' },
      { 'text': 'Queue to a specific queue', 'value': 'alternate queue action' }
    ],
    groupPNRQueueLocation : 'Queue Location for Group PNRs',
    tktSegmentsOptions: 'Ticketed Segments Processing Options',
    ticketedPNRQueueLocation : 'Queue Location for Ticketed Segments',
    enableProcessTkt: 'Enable Processing Ticketed PNR',
    actionMessageTypeForHost: 'Host/co-Host',
    actionMessageTypeForHostOptions : [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'RM/L', value: 'restrictedRemark'}
    ],
    rmlMessageAuthorizedOffices: 'Authorized Offices for RM/L Message',
    actionMessageTypeForNonHost: 'Non-Host',
    actionMessageTypeForNonHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'}
    ],
    waiverOverrideMessageType: 'Message Type for Override and Waiver',
    waiverOverrideMessageTypeOptions: [
      { text: 'SSR OTHS', value: 'ssrOths'},
      { text: 'RM', value: 'remark'},
      { text: 'OSI', value: 'osi'}
    ],
    ssrPnrWaiver: 'PNR Waiver Message Syntax',
    ssrSegmentWaiver: 'Segment Waiver Message Syntax',
    ssrOverride: 'Time Limit Override Message Syntax'
  },
  CHR: {
    churningConfiguration: 'Churning Configuration',
    enableChurningDetection: 'Enable Churning Detection',
    processDelayForCancelAction: 'Process Delay for Cancel Action (in minutes)',
    tooCloseToFirm: 'Too Close to Firm (in hours)',
    decisionPreference: 'Decision Preference',
    timeLimitBaseOption: [
      { value: 'action most recently confirmed segment', text: 'Act on most recently confirmed segment' },
      //{ value: 'action least recently confirmed segment', text: 'action least recently confirmed segment' }

    ],
    useDefaultChurningDecisionDescription: 'Note: Not applicable for warning message. Warning message is added in current PNR triggering evaluation.',
    tooCloseToDepartureTimeFrame: 'Too Close to Departure Time Frame (in hours)',
    ineligibleSplitQueueLocation: 'Ineligible Split Queue Location',
    overrideUsers: 'List of Authorized Users (for SSR waivers/overrides)',
    carrierOfficeIds: 'List of Airline Authorized Users',
    ariOfficeIds: 'List of Amadeus Exempted Users',
    useValidOverrideQueue: 'Queue Valid Waiver',
    validOverrideQueue: 'Valid Waiver Queue Location',
    useInvalidOverrideQueue: 'Queue Invalid Waiver',
    invalidOverrideQueue: 'Invalid Waiver Queue Location',
    errorQueue: 'Error Queue',
    actionMessageTypeForHost: 'Message for Host/Co-Host',
    actionMessageTypeForHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths' },
      { text: 'RM', value: 'remark' },
      { text: 'RM/L', value: 'restrictedRemark' }
    ],
    actionMessageTypeForNonHost: 'Message for Non-Host',
    rmlMessageAuthorizedOffices: 'Authorized Offices for RM/L Message',
    actionMessageTypeForNonHostOptions: [
      { text: 'SSR OTHS', value: 'ssrOths' }
    ],
    waiverOverrideMessageType: 'Message Type for Override and Waiver',
    waiverOverrideMessageTypeOptions: [
      { text: 'SSR OTHS', value: 'ssrOths' },
      { text: 'RM', value: 'remark' },
      { text: 'OSI', value: 'osi' }
    ],
    ssrPnrWaiver: 'PNR Waiver Message Syntax',
    ssrSegmentWaiver: 'Segment Waiver Message Syntax',
    ssrOverride: 'Time Limit Override Message Syntax'
  }
};
